// SPDX-License-Identifier: MIT

pragma solidity ^0.8.9;

import "@nilfoundation/smart-contracts/contracts/Nil.sol";

// In order to handle external transactions (i.e. generated by physical user)
// A smart contract needs to implement the `verifyExternal` method
// This functionality may be useful in any case where the contract being called
// Is used to pay for the execution of user request

// `ExternallyAccessible` contract can be accessed from outside of the system
// It exposes the `doSth` method which can be called with an appropriately signed transaction
// Note that the method can only be called with an external transaction
// Because of the `onlyExternal` modifier

// read more:
// https://docs.nil.foundation/nil/key-principles/account-abstraction
// https://docs.nil.foundation/nil/smart-contracts/receiving-ext-transactions
// https://docs.nil.foundation/nil/smart-contracts/func-modifiers/#onlyinternal-and-onlyexternal
// https://docs.nil.foundation/nil/nilcli/error-handling/#external-transaction-verification-failed

// ExternallyAccessible is a simple stateful contract which keeps user's public key
// The key then used to authorize external transactions sent to the contract by the user

// The contract inherits NilBase to have access to `onlyExternal` modifier
contract ExternallyAccessible is NilBase {
    bytes pubkey;

    constructor(bytes memory _pubkey) payable {
        pubkey = _pubkey;
    }

    function verifyExternal(uint256 hash, bytes calldata signature) external view returns (bool) {
        return Nil.validateSignature(
            pubkey, // the public key stored in the contract during creation
            hash, // the hash of the external transaction data
            signature // signature for the transaction generated with the user private key
        );
    }

    // dummy function which can only be called with an external transaction
    function doSth(uint256 data) onlyExternal public view returns (bool) {
        return data == 0;
    }
}
